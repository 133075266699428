<i18n src="@/i18n/user.json"></i18n>

<template lang="pug">
    .user-login.view
        .content-wrapper
            .header
                h2 Авторизация партнёра
            .content
                el-form(:model="login_form", @keydown.enter.native.prevent="login", :rules="login_form_rules", ref="login_form", label-position="top")
                    el-form-item(prop="email", :label="$t('user.login.form.email')")
                        el-input(v-model="login_form.email", type="email")
                    el-form-item(prop="password", :label="$t('user.login.form.password')")
                        el-input(v-model="login_form.password", type="password", autocomplete="new-password")
                    el-form-item
                        el-button(@click="login", type="primary") {{ $t('user.login.button') }}

</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { USER_LOGIN } from "@/store/types"

    const default_login_form = ()=>{
        return {
            email: '',
            password: '',
        }
    }

    export default {
        metaInfo(){
            return {
                title: this.$t('user.login.title')
            }
        },
        data () {
            return {
                login_form: default_login_form(),
                login_form_rules: {
                    email: [
                        { required: true, message: this.$t('user.form.validation.email.required'), trigger: 'blur' },
                        { type: 'email', message: this.$t('user.form.validation.email.email'), trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: this.$t('user.form.validation.password.required'), trigger: 'blur' },
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'auth_user'
            ]),
        },
        mounted(){
            // Redirect to profile page if the current user is logged in
            if(this.auth_user.loggedIn()) this.$router.push({ name: 'index' })
        },
        methods: {
            ...mapActions([
                USER_LOGIN,
            ]),
            // Execute login action
            login()
            {
                // Validate login form
                this.$refs['login_form'].validate((valid) => {
                    if (valid) {
                        this.USER_LOGIN(this.login_form)
                            .then(()=>{
                                // Redirect unverified user
                                if(!this.auth_user.emailVerified_at){
                                    this.$router.push({name: 'user.verify'})
                                // Redirect to the index page
                                }else{
                                    this.$router.push({ name: 'index' })
                                }
                            }).catch((error)=>{
                                this.$root.pushAppMessages(error.response.data)
                            })
                    } else return false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-login{
        .el-form{
            width: 50%;
        }
    }
</style>
